<template>
  <div>

    <div class="breadCurmb mt-3">

      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>

      <span>/</span>

      <label>{{ $t("WorkGroup") }}</label>

      <span>/</span>

      <label style="cursor:pointer" @click="goBack">


        <span >
          {{ $t('العملاء') }}
        </span>

      </label>

      <span>/</span>

      <label>{{$t("Add") }}</label>

    </div>
    <v-container fluid>

      <v-row class="mt-3">
        <v-col md="12">
          <v-alert border="top" color="blue lighten-2" dark>
            <h4>{{ $t("AddNewUser") }}</h4>
          </v-alert>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col class="py-0" :cols="4">
          <div :class="{'has-error': errors.has(`addEditValidation.first_name`) }">
            <label>{{ $t("الاسم الاول") }}</label>
            <v-text-field
                class="d-block my-2"
                type="text"
                name="first_name"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('first_name')"
                hide-details
                dense
                outlined
                v-model="formData.first_name"
            ></v-text-field>
          </div>

          <div class="help-block" v-if="errors.has(`addEditValidation.first_name`)">
            {{ errors.first(`addEditValidation.first_name`) }}
          </div>
        </v-col>
        <v-col class="py-0" :cols="4">
          <div :class="{'has-error': errors.has(`addEditValidation.second_name`) }">
            <label>{{ $t("الاسم الثاني") }}</label>
            <v-text-field
                class="d-block my-2"
                type="text"
                name="second_name"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('second_name')"
                hide-details
                dense
                outlined
                v-model="formData.second_name"
            ></v-text-field>
          </div>

          <div class="help-block" v-if="errors.has(`addEditValidation.second_name`)">
            {{ errors.first(`addEditValidation.second_name`) }}
          </div>
        </v-col>
        <v-col class="py-0" :cols="4">
          <div :class="{'has-error': errors.has(`addEditValidation.sur_name`) }">
            <label>{{ $t("اللقب") }}</label>
            <v-text-field
                class="d-block my-2"
                type="text"
                name="sur_name"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('sur_name')"
                hide-details
                dense
                outlined
                v-model="formData.sur_name"
            ></v-text-field>
          </div>

          <div class="help-block" v-if="errors.has(`addEditValidation.sur_name`)">
            {{ errors.first(`addEditValidation.sur_name`) }}
          </div>
        </v-col>
        <v-col class="py-0" :cols="6">
          <div :class="{'has-error': errors.has(`addEditValidation.phone`) }">
            <label>{{ $t("Mobile") }}</label>
            <v-text-field
                class="d-block my-2"
                type="text"
                name="phone"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('phone')"
                hide-details
                dense
                outlined
                v-model="formData.phone"
            ></v-text-field>
          </div>

          <div class="help-block" v-if="errors.has(`addEditValidation.phone`)">
            {{ errors.first(`addEditValidation.phone`) }}
          </div>
        </v-col>
        <v-col class="py-0" :cols="6">
          <div :class="{'has-error': errors.has(`addEditValidation.mobile`) }">
            <label>{{ $t("رقم الجوال للمندوب") }}</label>
            <v-text-field
                class="d-block my-2"
                type="text"
                name="mobile"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('mobile')"
                hide-details
                dense
                outlined
                v-model="formData.mobile"
            ></v-text-field>
          </div>

          <div class="help-block" v-if="errors.has(`addEditValidation.mobile`)">
            {{ errors.first(`addEditValidation.mobile`) }}
          </div>
        </v-col>
<!--        <v-col class="py-0" :cols="6">-->
<!--          <div-->
<!--              :class="{-->
<!--              'has-error': errors.has(`addEditValidation.password`),-->
<!--            }"-->
<!--          >-->
<!--            <label>{{ $t("Password") }}</label>-->

<!--          <div class="password-field-wrapper">-->
<!--            <v-text-field-->
<!--                class="d-block my-2"-->
<!--                :type="passwordFieldType"-->
<!--                name="password"-->
<!--                data-vv-scope="addEditValidation"-->
<!--                v-validate="'required'"-->
<!--                :data-vv-as="$t('Password')"-->
<!--                hide-details-->
<!--                dense-->
<!--                outlined-->
<!--                v-model="formData.password"-->
<!--                style="width: 600px;"-->
<!--                ref="password"-->
<!--            ></v-text-field>-->
<!--          </div>-->

<!--          <div class="button-wrapper">-->
<!--            <v-btn icon @click="togglePasswordVisibility('password')">-->
<!--              <v-icon>{{ passwordFieldVisible ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>-->
<!--            </v-btn>-->
<!--          </div>-->

<!--          <div-->
<!--              class="help-block"-->
<!--              v-if="errors.has(`addEditValidation.password`)"-->
<!--          >-->
<!--            {{ errors.first(`addEditValidation.password`) }}-->
<!--          </div>-->
<!--          </div>-->
<!--        </v-col>-->

<!--        <v-col class="py-0" :cols="6">-->
<!--          <div :class="{ 'has-error': errors.has(`addEditValidation.confirm_password`) }">-->
<!--            <label>{{ $t("ConfirmPassword") }}</label>-->
<!--            <div class="password-field-wrapper">-->
<!--              <v-text-field-->
<!--                  class="d-block my-2"-->
<!--                  :type="confirmPasswordFieldType"-->
<!--                  name="confirm_password"-->
<!--                  data-vv-scope="addEditValidation"-->
<!--                  v-validate="'required|confirmed:password'"-->
<!--                  :data-vv-as="$t('ConfirmPassword')"-->
<!--                  hide-details-->
<!--                  dense-->
<!--                  outlined-->
<!--                  v-model="formData.confirm_password"-->
<!--                  style="width: 600px;"-->
<!--              ></v-text-field>-->
<!--            </div>-->
<!--            <div class="button-wrapper">-->
<!--              <v-btn icon @click="togglePasswordVisibility('confirm_password')">-->
<!--                <v-icon>{{ confirmPasswordFieldVisible ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>-->
<!--              </v-btn>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="help-block" v-if="errors.has(`addEditValidation.confirm_password`)">-->
<!--            {{ errors.first(`addEditValidation.confirm_password`) }}-->
<!--          </div>-->
<!--        </v-col>-->


        <v-col class="py-0" :cols="6">
          <div :class="{ 'has-error': errors.has(`addEditValidation.email`)}">
            <label>{{ $t("Email") }}</label>
            <v-text-field
                class="d-block my-2"
                type="text"
                name="email"
                data-vv-scope="addEditValidation"
                v-validate="''"
                :data-vv-as="$t('Email')"
                hide-details
                dense
                outlined
                v-model="formData.email"
            ></v-text-field>
          </div>

          <div class="help-block" v-if="errors.has(`addEditValidation.email`)">
            {{ errors.first(`addEditValidation.email`) }}
          </div>
        </v-col>

        <v-col class="py-0" :cols="6">
          <div
              :class="{
                    'has-error': errors.has(`addEditValidation.birth_date`),
                  }"
          >
            <label>{{ $t("BirthDate") }}</label>
            <el-date-picker
                style="width: 100%"
                v-model="formData.birth_date"
                type="date"
                v-validate="'required'"
                data-vv-scope="addEditValidation"
                :data-vv-as="$t('BirthDate')"
                class="d-block my-2"
                name="birth_date"
                hide-details
                dense
                outlined
            >
            </el-date-picker>
          </div>

          <div
              class="help-block"
              v-if="errors.has(`addEditValidation.birth_date`)"
          >
            {{ errors.first(`addEditValidation.birth_date`) }}
          </div>
        </v-col>


        <v-col class="py-0" :cols="6">
          <div :class="{  'has-error': errors.has(`addEditValidation.gender`)}">
            <label>{{ $t("Gender") }}</label>
            <v-autocomplete
                class="d-block my-2"
                name="gender"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('gender')"
                hide-details
                dense
                outlined
                v-model="formData.gender"
                item-value="id"
                item-text="name"
                :items="genderList"
                clearable
            ></v-autocomplete>
          </div>

          <div class="help-block" v-if="errors.has(`addEditValidation.gender`)">
            {{ errors.first(`addEditValidation.gender`) }}
          </div>
        </v-col>

        <v-col class="py-0" :cols="6">
          <div :class="{  'has-error': errors.has(`addEditValidation.workout_plan`)}">
            <label>{{ $t("كم مرة تتمرن اسبوعيا") }}</label>
            <v-autocomplete
                class="d-block my-2"
                name="workout_plan"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('workout_plan')"
                hide-details
                dense
                outlined
                v-model="formData.workout_plan"
                item-value="id"
                item-text="name"
                :items="workoutPlanList"
                clearable
            ></v-autocomplete>
          </div>

          <div class="help-block" v-if="errors.has(`addEditValidation.workout_plan`)">
            {{ errors.first(`addEditValidation.workout_plan`) }}
          </div>
        </v-col>

        <v-col class="py-0" :cols="6">
          <div :class="{  'has-error': errors.has(`addEditValidation.dietary_plan`)}">
            <label>{{ $t("اختر نوع الحمية المفضل لك") }}</label>
            <v-autocomplete
                class="d-block my-2"
                name="dietary_plan"
                data-vv-scope="addEditValidation"
                v-validate="'required'"
                :data-vv-as="$t('dietary_plan')"
                hide-details
                dense
                outlined
                v-model="formData.dietary_plan"
                :items="dietaryPlanListToArray"
                clearable
                @change="dietaryPlanListClick"
            ></v-autocomplete>
          </div>

          <div class="help-block" v-if="errors.has(`addEditValidation.dietary_plan`)">
            {{ errors.first(`addEditValidation.dietary_plan`) }}
          </div>
        </v-col>


          <v-col class="py-0"  :cols="6">
            <div
                :class="{
                    'has-error': errors.has(`addEditValidation.height`),
                  }"
            >
              <label>{{ $t("Height") }}</label>
              <v-text-field
                  class="d-block my-2"
                  type="text"
                  name="height"
                  data-vv-scope="addEditValidation"
                  v-validate="'required|numeric'"
                  :data-vv-as="$t('Height')"
                  hide-details
                  dense
                  outlined
                  v-model="formData.height"
              ></v-text-field>
            </div>

            <div
                class="help-block"
                v-if="errors.has(`addEditValidation.height`)"
            >
              {{ errors.first(`addEditValidation.height`) }}
            </div>
          </v-col>

          <v-col class="py-0"  :cols="6">
            <div
                :class="{
                    'has-error': errors.has(`addEditValidation.weight`),
                  }"
            >
              <label>{{ $t("Weight") }}</label>
              <v-text-field
                  class="d-block my-2"
                  type="text"
                  name="weight"
                  data-vv-scope="addEditValidation"
                  v-validate="'required|numericOrFloat'"
                  :data-vv-as="$t('Weight')"
                  hide-details
                  dense
                  outlined
                  v-model="formData.weight"
              ></v-text-field>
            </div>

            <div
                class="help-block"
                v-if="errors.has(`addEditValidation.weight`)"
            >
              {{ errors.first(`addEditValidation.weight`) }}
            </div>
          </v-col>


          <v-col class="py-0" v-if="showCarbAndProtein" :cols="6">
            <div
                :class="{
                    'has-error': errors.has(`addEditValidation.carb`),
                  }"
            >
              <label>{{ $t("الكارب") }}</label>
              <v-text-field
                  class="d-block my-2"
                  type="text"
                  name="carb"
                  data-vv-scope="addEditValidation"
                  v-validate="'required|numeric'"
                  :data-vv-as="$t('carb')"
                  hide-details
                  dense
                  outlined
                  v-model="formData.carb"
              ></v-text-field>
            </div>

            <div
                class="help-block"
                v-if="errors.has(`addEditValidation.carb`)"
            >
              {{ errors.first(`addEditValidation.carb`) }}
            </div>
          </v-col>

          <v-col class="py-0" v-if="showCarbAndProtein" :cols="6">
            <div
                :class="{
                    'has-error': errors.has(`addEditValidation.protein`),
                  }"
            >
              <label>{{ $t("البروتين") }}</label>
              <v-text-field
                  class="d-block my-2"
                  type="text"
                  name="protein"
                  data-vv-scope="addEditValidation"
                  v-validate="'required|numeric'"
                  :data-vv-as="$t('protein')"
                  hide-details
                  dense
                  outlined
                  v-model="formData.protein"
              ></v-text-field>
            </div>

            <div
                class="help-block"
                v-if="errors.has(`addEditValidation.protein`)"
            >
              {{ errors.first(`addEditValidation.protein`) }}
            </div>
          </v-col>

      </v-row>



<!--      <v-card-text>-->
<!--        <v-container fluid>-->
<!--          <h2>بيانات العنوان للمنزل </h2>-->
<!--        </v-container>-->
<!--      </v-card-text>-->
<!--      <v-card-text>-->
<!--        <v-container fluid>-->
<!--          <v-row>-->
<!--            <v-col class="py-0" :cols="3">-->
<!--              <div-->
<!--                  :class="{-->
<!--                    'has-error': errors.has(`addEditValidation.City`),-->
<!--                  }"-->
<!--              >-->
<!--                <label>{{ $t("City") }}</label>-->
<!--                <v-autocomplete-->
<!--                    class="d-block my-2"-->
<!--                    name="City"-->
<!--                    data-vv-scope="addEditValidation"-->
<!--                    v-validate="'required'"-->
<!--                    :data-vv-as="$t('City')"-->
<!--                    hide-details-->
<!--                    dense-->
<!--                    outlined-->
<!--                    v-model="formData.customer_address.city_id"-->
<!--                    item-value="id"-->
<!--                    item-text="name"-->
<!--                    :items="cityList"-->
<!--                    @change="getNeighborhoodList(formData.customer_address.city_id)"-->
<!--                    clearable-->
<!--                ></v-autocomplete>-->
<!--              </div>-->

<!--              <div-->
<!--                  class="help-block"-->
<!--                  v-if="errors.has(`addEditValidation.City`)"-->
<!--              >-->
<!--                {{ errors.first(`addEditValidation.City`) }}-->
<!--              </div>-->
<!--            </v-col>-->

<!--            <v-col class="py-0" :cols="3">-->
<!--              <div-->
<!--                  :class="{-->
<!--                    'has-error': errors.has(`addEditValidation.Neighborhood`),-->
<!--                  }"-->
<!--              >-->
<!--                <label>{{ $t("Neighborhood") }}</label>-->
<!--                <v-autocomplete-->
<!--                    class="d-block my-2"-->
<!--                    name="Neighborhood"-->
<!--                    data-vv-scope="addEditValidation"-->
<!--                    v-validate="'required'"-->
<!--                    :data-vv-as="$t('Neighborhood')"-->
<!--                    hide-details-->
<!--                    dense-->
<!--                    outlined-->
<!--                    v-model="formData.customer_address.branch_id"-->
<!--                    item-value="id"-->
<!--                    item-text="name"-->
<!--                    :items="neighborhoodList"-->
<!--                    clearable-->
<!--                ></v-autocomplete>-->
<!--              </div>-->

<!--              <div-->
<!--                  class="help-block"-->
<!--                  v-if="errors.has(`addEditValidation.Neighborhood`)"-->
<!--              >-->
<!--                {{ errors.first(`addEditValidation.Neighborhood`) }}-->
<!--              </div>-->
<!--            </v-col>-->

<!--            <v-col class="py-0" :cols="3">-->
<!--              <div-->
<!--                  :class="{-->
<!--                    'has-error': errors.has(`addEditValidation.company`),-->
<!--                  }"-->
<!--              >-->
<!--                <label>{{ $t("Company") }}</label>-->
<!--                <v-autocomplete-->
<!--                    class="d-block my-2"-->
<!--                    name="company"-->
<!--                    data-vv-scope="addEditValidation"-->
<!--                    v-validate="'required'"-->
<!--                    :data-vv-as="$t('Company')"-->
<!--                    hide-details-->
<!--                    dense-->
<!--                    outlined-->
<!--                    v-model="formData.customer_address.company_id"-->
<!--                    item-value="id"-->
<!--                    item-text="name"-->
<!--                    :items="companyList"-->
<!--                    clearable-->
<!--                ></v-autocomplete>-->
<!--              </div>-->

<!--              <div-->
<!--                  class="help-block"-->
<!--                  v-if="errors.has(`addEditValidation.company`)"-->
<!--              >-->
<!--                {{ errors.first(`addEditValidation.company`) }}-->
<!--              </div>-->
<!--            </v-col>-->


<!--            <v-col class="py-0" :cols="3">-->
<!--              <div-->
<!--                  :class="{-->
<!--                    'has-error': errors.has(`addEditValidation.Period`),-->
<!--                  }"-->
<!--              >-->
<!--                <label>{{ $t("Period") }}</label>-->
<!--                <v-autocomplete-->
<!--                    class="d-block my-2"-->
<!--                    name="Period"-->
<!--                    data-vv-scope="addEditValidation"-->
<!--                    v-validate="'required'"-->
<!--                    :data-vv-as="$t('Period')"-->
<!--                    hide-details-->
<!--                    dense-->
<!--                    outlined-->
<!--                    v-model="formData.customer_address.period"-->
<!--                    item-value="id"-->
<!--                    item-text="name"-->
<!--                    :items="periodList"-->
<!--                    clearable-->
<!--                ></v-autocomplete>-->
<!--              </div>-->

<!--              <div-->
<!--                  class="help-block"-->
<!--                  v-if="errors.has(`addEditValidation.Period`)"-->
<!--              >-->
<!--                {{ errors.first(`addEditValidation.Period`) }}-->
<!--              </div>-->
<!--            </v-col>-->

<!--            <v-col class="py-0" :cols="6">-->
<!--              <div-->
<!--                  :class="{-->
<!--                    'has-error': errors.has(`addEditValidation.HomeNumber`),-->
<!--                  }"-->
<!--              >-->
<!--                <label>{{ $t("HomeNumber") }}</label>-->
<!--                <v-text-field-->
<!--                    class="d-block my-2"-->
<!--                    type="text"-->
<!--                    name="HomeNumber"-->
<!--                    data-vv-scope="addEditValidation"-->
<!--                    v-validate="''"-->
<!--                    :data-vv-as="$t('HomeNumber')"-->
<!--                    hide-details-->
<!--                    dense-->
<!--                    outlined-->
<!--                    v-model="formData.customer_address.home_number"-->
<!--                ></v-text-field>-->
<!--              </div>-->

<!--              <div-->
<!--                  class="help-block"-->
<!--                  v-if="errors.has(`addEditValidation.HomeNumber`)"-->
<!--              >-->
<!--                {{ errors.first(`addEditValidation.HomeNumber`) }}-->
<!--              </div>-->
<!--            </v-col>-->


<!--            <v-col class="py-0" :cols="6">-->
<!--              <div-->
<!--                  :class="{-->
<!--                    'has-error': errors.has(`addEditValidation.address`),-->
<!--                  }"-->
<!--              >-->
<!--                <label>{{ $t("Address") }}</label>-->
<!--                <v-text-field-->
<!--                    class="d-block my-2"-->
<!--                    type="text"-->
<!--                    name="address"-->
<!--                    data-vv-scope="addEditValidation"-->
<!--                    v-validate="''"-->
<!--                    :data-vv-as="$t('Address')"-->
<!--                    hide-details-->
<!--                    dense-->
<!--                    outlined-->
<!--                    v-model="formData.customer_address.address"-->
<!--                ></v-text-field>-->
<!--              </div>-->

<!--              <div-->
<!--                  class="help-block"-->
<!--                  v-if="errors.has(`addEditValidation.address`)"-->
<!--              >-->
<!--                {{ errors.first(`addEditValidation.address`) }}-->
<!--              </div>-->
<!--            </v-col>-->


<!--            <v-col class="py-0"  :cols="6">-->
<!--              <div-->
<!--                  :class="{-->
<!--                    'has-error': errors.has(`addEditValidation.link_location`),-->
<!--                  }"-->
<!--              >-->
<!--                <label> {{ $t("عنوان المستخدم") }}</label>-->
<!--                <v-text-field-->
<!--                    class="d-block my-2"-->
<!--                    type="text"-->
<!--                    name="link_location"-->
<!--                    data-vv-scope="addEditValidation"-->
<!--                    v-validate="'required'"-->
<!--                    :data-vv-as="$t('link_location')"-->
<!--                    hide-details-->
<!--                    dense-->
<!--                    outlined-->
<!--                    v-model="formData.customer_address.link_location"-->
<!--                ></v-text-field>-->
<!--              </div>-->

<!--              <div class="help-block"  v-if="errors.has(`addEditValidation.link_location`)">-->
<!--                {{ errors.first(`addEditValidation.link_location`) }}-->
<!--              </div>-->
<!--            </v-col>-->


<!--            <v-col class="py-0" :cols="6">-->
<!--              <div-->
<!--                  :class="{-->
<!--                    'has-error': errors.has(`addEditValidation.notes2`),-->
<!--                  }"-->
<!--              >-->
<!--                <label>{{ $t("Notes") }}</label>-->
<!--                <v-textarea-->
<!--                    class="d-block my-2"-->
<!--                    type="text"-->
<!--                    name="notes2"-->
<!--                    rows="4"-->
<!--                    data-vv-scope="addEditValidation"-->
<!--                    v-validate="''"-->
<!--                    :data-vv-as="$t('Notes')"-->
<!--                    hide-details-->
<!--                    dense-->
<!--                    outlined-->
<!--                    v-model="formData.customer_address.notes"-->
<!--                ></v-textarea>-->
<!--              </div>-->

<!--              <div-->
<!--                  class="help-block"-->
<!--                  v-if="errors.has(`addEditValidation.notes2`)"-->
<!--              >-->
<!--                {{ errors.first(`addEditValidation.notes2`) }}-->
<!--              </div>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--        </v-container>-->
<!--      </v-card-text>-->

      <v-card-text>
        <v-container v-if="errorsApi.length > 0">
          <h1>الاخطاء</h1>
            <div v-for="(error, index) in errorsApi" :key="index">
              {{ error }}
            </div>
        </v-container>
      </v-card-text>

      <v-row class="float-left mt-5 mb-5">
        <v-btn  @click="save" class="mr-2 ml-2" color="primary" dark outlined  small>
          {{ $t("Save") }}
        </v-btn>
      </v-row>

    </v-container>
  </div>
</template>
<script>
import { Validator } from 'vee-validate';


Validator.extend('numericOrFloat', {
  validate: value => {
    // Check if the value is either numeric or a floating-point number
    return /^\d+(\.\d+)?$/.test(value);
  },
  message: field => `The ${field} field must be a valid number or a floating-point number.`,
});
export default {
  name: "usersAdd",
  computed: {
    dietaryPlanListToArray() {
      return Object.keys(this.dietaryPlanList).map(key => ({
        value: key,
        text: this.dietaryPlanList[key]
      }));
    },
    passwordFieldType() {
      return this.passwordFieldVisible ? 'text' : 'password';
    },
    confirmPasswordFieldType() {
      return this.confirmPasswordFieldVisible ? 'text' : 'password';
    },
  },
  data() {
    return {
      errorsApi: [],
      passwordFieldVisible: false,
      confirmPasswordFieldVisible: false,
      showCarbAndProtein:false,
      genderList: [
          'male','female'
      ],
      workoutPlanList: [
          1,2,3,4,5,6,7
      ],
      dietaryPlanList: {
        'maintain_weight': 'المحافظه علي الوزن',
        'low_weight': 'خسارة الوزن',
        'gain_weight': 'زيادة الوزن',
        'zero_carb': 'بدون كارب',
        'vegetarian': 'نباتي',
        'customize': 'اختياري',
      },
      cityList: [],
      neighborhoodList: [],
      companyList: [],
      periodList: [
        {
          id: 1,
          name: "صباحي",
        },
        {
          id: 2,
          name: "مسائي",
        },
      ],
      formData: {
        first_name: "",
        second_name: "",
        sur_name: "",
        phone: "",
        mobile: "",
        email: "",
        // password: "",
        // confirm_password: "",
        birth_date: "2000-01-01",
        height: 0,
        weight: 0,
        carb: null,
        protein: null,
        gender: 'male',
        workout_plan: 1,
        dietary_plan: "maintain_weight",
        link_location:null,
        customer_address : {
          city_id: null,
          branch_id: null,
          home_address: null,
          period: null,
          home_number: null,
          company_id: null,
          group_name_id: null,
          address: null,
          notes: null,
          link_location:null,
        }
      },
    };
  },

  created() {

    this.$store.dispatch(`subscribe/cityList`).then((res) => {
      this.cityList = res.data;
    });

    this.$store.dispatch(`subscribe/companyList`).then((res) => {
      this.companyList = res.data;
    });


  },
  methods:{

    goBack() {
      this.$router.go(-1);
    },

    togglePasswordVisibility(field) {
      if (field === 'password') {
        this.passwordFieldVisible = !this.passwordFieldVisible;
      } else if (field === 'confirm_password') {
        this.confirmPasswordFieldVisible = !this.confirmPasswordFieldVisible;
      }
    },

    getNeighborhoodList(id) {
      this.$store.dispatch(`subscribe/branchList`, id).then((res) => {
        this.neighborhoodList = res.data;
      });
    },
    save(){

      this.$validator.validateAll("addEditValidation").then((valid) => {

          if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

          let sendData = JSON.parse(JSON.stringify(this.formData));

          this.$store.dispatch(`customer/createCustomer`, sendData).then((res) => {
            this.notifySuccess("Save", res);
            this.$router.push({ name: "customers" });
          }).catch((error) => {

            let errorsArray = [];

            // Check if the error object has response and data properties
            if (error.response && error.response.data) {
              const responseData = error.response.data;

              // Check if the response data has an 'errors' array
              if (Array.isArray(responseData.errors)) {
                // If 'errors' is an array, add all error messages to the errorsArray
                errorsArray = responseData.errors;
              } else if (typeof responseData.errors === 'object') {
                // If 'errors' is an object, iterate over its values and add them to errorsArray
                errorsArray = Object.values(responseData.errors);
              } else if (responseData.message) {
                // If there's a single error message, add it to the errorsArray
                errorsArray.push(responseData.message);
              }
            } else {
              // If the error object doesn't have expected properties, log the error
              console.error('Unexpected error:', error);
              errorsArray.push('An unexpected error occurred.');
            }

            this.errorsApi = errorsArray;
          })

      });
    },

    dietaryPlanListClick(item){
      if (item === 'customize'){
        this.showCarbAndProtein = true
        this.formData.carb = null
        this.formData.protein = null
      }else {
        this.showCarbAndProtein = false
      }
    }
  }
};
</script>

<style>

.password-field-wrapper,
.button-wrapper {
  display: inline-block;
  //vertical-align: top;
  margin: 12px; /* Adjust as needed */
}

.v-btn__content .v-icon.v-icon{
  color: black !important;
}
</style>